import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import { useHistory } from "react-router-dom";

function Login() {
  const auth = getAuth();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      if (user.user) {
        return (window as any).toastr.success("Success");
      }
    } catch (error: any) {
      return (window as any).toastr.error("Invalid email or password");
    }
  };

  const goReg = () => {
    history.push("/register");
  };
  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a className="h1">
              <b>Virtual OTP</b>
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in now</p>
            <div className="input-group mb-3">
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8"></div>
              <div className="col-4">
                <button onClick={onSubmit} className="btn btn-primary btn-block">
                  Sign In
                </button>
              </div>
            </div>

            {/* <p className="mb-1">
              <a href="forgot-password.html">I forgot my password</a>
            </p> */}
            <p className="mb-0">
              <a onClick={goReg} className="text-center">
                Register
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
