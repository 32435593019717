import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { signOut, getAuth } from "@firebase/auth";

function TopNav() {
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const auth = getAuth();

  return (
    <div className="main-header navbar navbar-expand-md navbar-light navbar-white">
      <div className="container">
        <a onClick={() => history.push("/home")} className="navbar-brand">
          <span className="brand-text font-weight-light">Virtual OTP</span>
        </a>

        <button
          className="navbar-toggler order-1"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setToggle(!toggle)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={toggle ? "show collapse navbar-collapse order-3" : "collapse navbar-collapse order-3"} id="navbarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a onClick={() => history.push("/home")} className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a onClick={() => history.push("/reload")} className="nav-link">
                Reload
              </a>
            </li>
            <li className="nav-item">
              <a onClick={() => history.push("/api")} className="nav-link">
                API
              </a>
            </li>
            <li className="nav-item">
              <a onClick={() => history.push("/profile")} className="nav-link">
                Profile
              </a>
            </li>
            <li className="nav-item">
              <button onClick={() => signOut(auth)} className=" btn btn-block btn-danger">
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
