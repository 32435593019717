import React, { useState } from "react";
import { getAuth, updatePassword } from "@firebase/auth";
import Footer from "./Footer";
import TopNav from "./TopNav";
import { AuthUserContext } from "./AuthWrapper";

function Profile() {
  const authUser = React.useContext(AuthUserContext);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const onSubmit = async () => {
    try {
      if (!password || password.length < 6) return (window as any).toastr.error("Invalid password");
      if (password !== password2) return (window as any).toastr.error("Password not match");
      if (!authUser) return;
      await updatePassword(authUser, password);
      (window as any).toastr.success("Success");
    } catch (err: any) {
      return (window as any).toastr.error("Failed");
    }
  };

  return (
    <div className="hold-transition layout-top-nav">
      <div className="wrapper">
        <TopNav />

        <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Profile</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form className="form-horizontal">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Email</label>
                          <div className="col-sm-10">
                            <input type="email" className="form-control" disabled value={authUser?.email || ""} id="inputEmail" placeholder="Email" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">New Password</label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Confirm Password</label>
                        <div className="col-sm-10">
                          <input
                            type="password"
                            className="form-control"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            placeholder="Password"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-4">
                          <button onClick={() => onSubmit()} className="btn btn-info btn-block">
                            Save
                          </button>
                        </div>
                        <div className="col-8"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Profile;
