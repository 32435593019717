import { where, query, orderBy, collection, onSnapshot, getFirestore } from "@firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../components/AuthWrapper";
import IOrder from "./../interfaces/order";
import { limit } from "firebase/firestore";

const useFetchOrder = () => {
  const authUser = useContext(AuthUserContext);
  const firestore = getFirestore();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!authUser) return;
    const orderCollectionRef = collection(firestore, "order");
    const baseQuery = query(orderCollectionRef, where("userID", "==", authUser.uid), orderBy("orderDate", "desc"), limit(20));

    return onSnapshot(
      baseQuery,
      (snapshot) => {
        setOrders(
          snapshot.docs.map((doc) => {
            const order = doc.data() as IOrder;
            return { ...order, orderDate: order.orderDate && new Date(order.orderDate.seconds * 1000) };
          })
        );
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      }
    );
  }, [firestore, authUser]);
  return { orders, isLoading };
};

export default useFetchOrder;
