import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "@firebase/auth";
import { useHistory } from "react-router-dom";

function Register() {
  const auth = getAuth();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const onSubmit = async () => {
    try {
      if (password !== password2) return (window as any).toastr.error("Password not match");
      const user = await createUserWithEmailAndPassword(auth, email, password);
      if (user.user) {
        (window as any).toastr.success("Success");
      }
    } catch (error: any) {
      const { code } = error;
      let messagge = "";
      if (code === "auth/email-already-in-use") messagge = "Email already exists";
      if (code === "auth/invalid-email") messagge = "Invalid email";
      if (code === "auth/weak-password") messagge = "Password is weak";

      return (window as any).toastr.error(messagge);
    }
  };

  const goLogin = () => {
    history.push("/login");
  };

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a className="h1">
              <b>Virtual OTP</b>
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign up now</p>
            <div className="input-group mb-3">
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div className="input-group mb-3">
              <input
                type="password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                className="form-control"
                placeholder="Confirm Password"
              />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-8"></div>
              <div className="col-4">
                <button onClick={onSubmit} className="btn btn-primary btn-block">
                  Register
                </button>
              </div>
            </div>

            <p className="mb-0">
              <a onClick={goLogin} className="text-center">
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
