import { getAuth } from "@firebase/auth";
import axios from "axios";
import { delay } from "./utils";

const instance = axios.create();
instance.interceptors.request.use(async function (config: any) {
  const auth = getAuth();
  let retries = 10;

  let jwt = await auth.currentUser?.getIdToken();

  while (retries > 0) {
    if (!jwt) {
      await delay(1000);
      jwt = await auth.currentUser?.getIdToken();
      retries--;
    } else {
      config.headers = {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      };
      retries = 0;
    }
  }

  config.baseURL = "https://api.virtual-otp.cf/";
  // config.baseURL = "http://localhost:3001/";
  return config;
});

export default instance;
