import React, { createContext, useEffect, useState } from "react";
import { getAuth, User } from "@firebase/auth";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import MyRoute from "./MyRoute";
import { useHistory } from "react-router";
import IUser from "../interfaces/user";

const AuthUserContext = createContext<User | null>(null);
const UserContext = createContext<IUser | null>(null);

function AuthWrapper() {
  const firestore = getFirestore();
  const auth = getAuth();
  const history = useHistory();
  const [authUser, setAuthUser] = useState<User | null>(null);

  useEffect(() => {
    return auth.onAuthStateChanged((usr) => {
      setAuthUser(usr);
      if (usr) history.push("/home");
      else history.push("/login");
    });
  }, [auth, history]);

  const [user, setUser] = useState<IUser | null>(null);
  useEffect(() => {
    if (!authUser) return;
    const userDoc = doc(firestore, "user", authUser.uid);
    return onSnapshot(userDoc, (snapshot) => {
      const user = snapshot.data() as IUser;
      setUser(user);
    });
  }, [authUser, firestore]);

  return (
    <AuthUserContext.Provider value={authUser}>
      <UserContext.Provider value={user}>
        <MyRoute />
      </UserContext.Provider>
    </AuthUserContext.Provider>
  );
}

export { UserContext, AuthUserContext };
export default AuthWrapper;
