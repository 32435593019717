import React, { Fragment, useState } from "react";
import QRCode from "react-qr-code";

import TRANSACTION_STATUS from "../enum/transactionStatus";
import useAPI from "../hooks/useAPI";
import useFetchTransactions from "../hooks/useFetchTransactions";
import { UserContext } from "./AuthWrapper";
import Footer from "./Footer";
import TopNav from "./TopNav";

function Reload() {
  const user = React.useContext(UserContext);

  const [usdPayment, setUsdPayment] = useState(0);
  const { transactions } = useFetchTransactions();

  const { intentTopup, paymentIntent, loadingPaymentIntent } = useAPI();

  const getStatus = (id: number) => {
    switch (id) {
      case TRANSACTION_STATUS.COMPLETED:
        return "Completed";
      case TRANSACTION_STATUS.PENDING:
        return "Pending";
      case TRANSACTION_STATUS.FAILED:
        return "Failed";

      default:
        return "Error";
    }
  };

  const getStatusStyle = (id: number) => {
    switch (id) {
      case TRANSACTION_STATUS.COMPLETED:
        return "badge bg-success";
      case TRANSACTION_STATUS.PENDING:
        return "badge bg-warning";
      case TRANSACTION_STATUS.FAILED:
        return "badge bg-danger";

      default:
        return "badge bg-danger";
    }
  };

  const convertCredit = (usd: number) => usd * 70;

  return (
    <div className="hold-transition layout-top-nav">
      <div className="wrapper">
        <TopNav />

        <div className="content-wrapper">
          <div className="content-header"></div>
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Your Infomation</h3>
                    </div>
                    <div className="card-body">
                      <p>
                        <b>Email:</b> {user?.email}
                      </p>
                      <p>
                        <b>Credit Balance:</b> {user?.credit.toFixed(2)}
                      </p>
                      <p>
                        <b>Total Reload (Current week):</b> {user?.weeklyReload.toFixed(2)}
                      </p>
                      {/* <p>
                        <b>Wallet Balance (USD):</b> {user?.usd.toFixed(2)}
                      </p> */}
                      <p>
                        <b>Discount Tier:</b> {user?.discountPercent} %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Loyalty Program</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Minimum reload (USD)</th>
                                <th>Discount tier (%)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td> 0 </td>
                                <td> 0% </td>
                              </tr>
                              <tr>
                                <td> 200 </td>
                                <td> 10% </td>
                              </tr>
                              <tr>
                                <td> 400 </td>
                                <td> 20% </td>
                              </tr>
                              <tr>
                                <td> 800 </td>
                                <td> 30% </td>
                              </tr>
                              <tr>
                                <td> 1200 </td>
                                <td> 40% </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <b>Note:</b> <br />
                          <ol>
                            <li>Discount tier is determined by the sum of top-up in a calendar week.</li>
                            <li>On every Sunday, you will be assigned a discount tier based on your sum of top-up in the previous week.</li>
                            <li>
                              Eg. You reload 400 USD today. Your current bonus tier will remained 0%. Until the following Sunday, your bonus tier will
                              become 20%.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Reload Wallet Balance (Litecoin Only)</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-5">
                          <div className="input-group mb-3">
                            <input
                              type="number"
                              min={5}
                              max={500}
                              value={usdPayment}
                              onChange={(e) => setUsdPayment(+e.target.value)}
                              className="form-control"
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">USD</span>
                            </div>
                          </div>
                          <div className="input-group mb-3">
                            <input type="number" value={convertCredit(usdPayment)} disabled className="form-control" />
                            <div className="input-group-append">
                              <span className="input-group-text">Credit</span>
                            </div>
                          </div>
                          <p>Note: USD will be auto converted into OTP credit</p>
                          <p>5 ~ 500 USD only</p>
                          <p>
                            <b>Important: You will need to complete the LTC transaction in 20 minutes</b>
                          </p>
                          <div className="row">
                            <div className="col-lg-6">
                              <button
                                type="button"
                                onClick={() => {
                                  intentTopup(usdPayment);
                                }}
                                disabled={loadingPaymentIntent}
                                className="btn btn-block btn-success"
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1">
                          <div style={{ height: "30px" }} />
                        </div>

                        {loadingPaymentIntent ? (
                          <div className="col-lg-6 d-flex justify-content-center align-items-center" style={{ height: "400px" }}>
                            <div className="spinner-border" role="status" style={{ width: "100px", height: "100px" }}>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : paymentIntent ? (
                          <div className="col-lg-6">
                            <div className="input-group mb-3">
                              <input type="number" value={(paymentIntent as any).ltcAmount} disabled className="form-control" />
                              <div className="input-group-append">
                                <span className="input-group-text">LTC</span>
                              </div>
                            </div>
                            <div className="input-group mb-3">
                              <input type="text" disabled value={(paymentIntent as any).address} className="form-control" />
                              <div className="input-group-append">
                                <span className="input-group-text">Address</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center ">
                              <QRCode value={"litecoin:" + (paymentIntent as any).address} />
                            </div>
                            <p style={{ color: "red" }}>
                              <br />
                              <b>Please transfer the exact LTC amount</b> <br />
                              <b>Payment process will take up to 10 minutes</b>
                            </p>
                          </div>
                        ) : (
                          <Fragment />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Transaction history</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12" style={{ overflow: "scroll" }}>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>USD</th>
                                <th>LTC</th>
                                <th>Date</th>
                                <th style={{ width: "40px" }}>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map((o, index) => (
                                <tr key={index}>
                                  <td>{o.transactionID}</td>
                                  <td>{o.usdAmount}</td>
                                  <td>{o.ltcAmount}</td>
                                  <td>{o.transactionDate.toLocaleString()}</td>
                                  <td>
                                    <span className={getStatusStyle(o.status)}> {getStatus(o.status)} </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Reload;
