import { useState } from "react";
import axios from "../customAxios";
import IUser from "../interfaces/user";

const useAPI = () => {
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);
  const [loadingConvert, setLoadingConvert] = useState(false);

  const getNumber = async (id: any) => {
    try {
      await axios.post("number/new", {
        serviceID: id,
      });
      (window as any).toastr.success("Success");
    } catch (error: any) {
      (window as any).toastr.error("Failed");
    }
  };

  const cancelNumber = async (id: any) => {
    try {
      await axios.post("number/cancel", {
        orderID: id,
      });
      (window as any).toastr.success("Cancelled");
    } catch (error: any) {
      (window as any).toastr.error("Failed");
    }
  };
  const intentTopup = async (usdAmount: any) => {
    try {
      setLoadingPaymentIntent(true);
      const finalUsdAmount = +(+usdAmount).toFixed(2);
      if (finalUsdAmount < 5 || finalUsdAmount > 500) return (window as any).toastr.error("Invalid amount");
      const res = await axios.post("payment/intent", {
        usdAmount: finalUsdAmount,
      });
      if (res.data) {
        setPaymentIntent(res.data);
      }
      (window as any).toastr.success("Success");
    } catch (error: any) {
      console.log(error);
      (window as any).toastr.error("Failed, please try again later");
    } finally {
      setLoadingPaymentIntent(false);
    }
  };
  const convertCredit = async (usdAmount: any, user: IUser | null) => {
    try {
      setLoadingConvert(true);
      if (!user) return;
      if (!usdAmount) return (window as any).toastr.error("Invalid amount");
      if (user.usd < usdAmount) return (window as any).toastr.error("Not enough balance");
      await axios.post("payment/convertToCredit", {
        usdAmount,
      });
      (window as any).toastr.success("Success");
    } catch (error: any) {
      (window as any).toastr.error("Failed");
    } finally {
      setLoadingConvert(false);
    }
  };

  return { getNumber, cancelNumber, intentTopup, convertCredit, paymentIntent, loadingPaymentIntent, loadingConvert };
};

export default useAPI;
