import React, { Fragment, useState } from "react";
import ORDER_STATUS from "../enum/orderStatus";
import useAPI from "../hooks/useAPI";
import useFetchOrder from "../hooks/useFetchOrder";
import useFetchService from "../hooks/useFetchService";
import { AuthUserContext, UserContext } from "./AuthWrapper";
import Footer from "./Footer";
import TopNav from "./TopNav";

function Home() {
  const authUser = React.useContext(AuthUserContext);
  const user = React.useContext(UserContext);

  const { getNumber, cancelNumber } = useAPI();

  const [search, setSearch] = useState("");

  const { services } = useFetchService();
  const { orders } = useFetchOrder();
  const filteredServices = () => {
    return services.filter((s: any) => s.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
  };

  const getStatus = (id: number) => {
    switch (id) {
      case ORDER_STATUS.COMPLETED:
        return "Completed";
      case ORDER_STATUS.PENDING:
        return "Waiting";
      case ORDER_STATUS.PENDING_CANCELLATION:
        return "Cancelling";
      case ORDER_STATUS.CANCELLED:
        return "Cancelled";

      default:
        return "Error";
    }
  };

  const getStatusStyle = (id: number) => {
    switch (id) {
      case ORDER_STATUS.COMPLETED:
        return "badge bg-success";
      case ORDER_STATUS.PENDING:
        return "badge bg-warning";
      case ORDER_STATUS.PENDING_CANCELLATION:
        return "badge bg-danger";
      case ORDER_STATUS.CANCELLED:
        return "badge bg-danger";

      default:
        return "badge bg-danger";
    }
  };

  return (
    <div className="hold-transition layout-top-nav">
      <div className="wrapper">
        <TopNav />

        <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">
                    Welcome <small>{authUser?.email}</small>
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    Virtual OTP (V-OTP) is a platform focused on providing quality Indonesia number OTP service for our customer.{" "}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="info-box">
                    <span className="info-box-icon bg-info">
                      <i className="fa fa-phone"></i>
                    </span>

                    <div className="info-box-content">
                      <span className="info-box-text">OTP Credit</span>
                      <span className="info-box-number">{user?.credit.toFixed(2)}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <span className="info-box-icon bg-success">
                      <i className="fa fa-dollar"></i>
                    </span>

                    <div className="info-box-content">
                      <span className="info-box-text">Balance (USD)</span>
                      <span className="info-box-number">{user?.usd.toFixed(2)}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info-box">
                    <span className="info-box-icon bg-danger">
                      <i className="fa fa-percent"></i>
                    </span>

                    <div className="info-box-content">
                      <span className="info-box-text">Discount (%)</span>
                      <span className="info-box-number">{user?.discountPercent}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Search Service</h3>
                    </div>
                    <div className="card-body">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Search Service</label>
                          <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            className="form-control"
                            placeholder="Eg: telegram"
                          />
                        </div>
                        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
                          <table className="table table-bordered" style={{ maxHeight: "100px" }}>
                            <thead>
                              <tr>
                                <th style={{ width: "10px" }}>#</th>
                                <th>Service</th>
                                <th>Credit</th>
                                <th style={{ width: "40px" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredServices().map((s: any, index) => (
                                <tr key={index}>
                                  <td>{s.id}.</td>
                                  <td>{s.name}</td>
                                  <td>{s.cost}</td>
                                  <td>
                                    <button type="button" onClick={() => getNumber(s.id)} className="btn btn-sm btn-block btn-primary">
                                      Buy
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Your Numbers</h3>
                    </div>

                    <div className="card-body">
                      <div style={{ overflow: "scroll" }}>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Number</th>
                              <th>OTP</th>
                              <th>Service</th>
                              <th>Price</th>
                              <th style={{ width: "40px" }}>Status</th>
                              <th style={{ width: "40px" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {orders.map((o, index) => (
                              <tr key={index}>
                                <td>{o.phoneCountryCode + o.phone}</td>
                                <td>{o.code}</td>
                                <td>{o.service}</td>
                                <td>{o.cost}</td>
                                <td>
                                  <span className={getStatusStyle(o.status)}> {getStatus(o.status)} </span>
                                </td>
                                <td>
                                  {o.status === ORDER_STATUS.PENDING ? (
                                    <button type="button" onClick={() => cancelNumber(o.orderID)} className="btn btn-sm btn-block btn-danger">
                                      Cancel
                                    </button>
                                  ) : (
                                    <Fragment />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
