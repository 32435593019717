import { useEffect, useState } from "react";
import axios from "../customAxios";

const useFetchService = () => {
  const [services, setServices] = useState([]);

  const fetchService = async () => {
    const res = await axios.get("services");
    setServices(res.data);
  };

  useEffect(() => {
    fetchService();
  }, []);

  return { services };
};

export default useFetchService;
