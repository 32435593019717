import React from "react";
import Footer from "./Footer";
import TopNav from "./TopNav";
import { UserContext } from "./AuthWrapper";

function Documentation() {
  const user = React.useContext(UserContext);

  return (
    <div className="hold-transition layout-top-nav">
      <div className="wrapper">
        <TopNav />

        <div className="content-wrapper">
          <div className="content-header">
            <div className="container">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">API documentation</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Your API key</h3>
                </div>
                <div className="card-body">
                  <div className="input-group mb-3">
                    <input type="text" disabled value={user?.apiKey} className="form-control" />
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Documentation</h3>
                </div>
                <div className="card-body">
                  <p>
                    Base URL: <b>https://api.virtual-otp.cf</b>
                  </p>
                  <p>All request must have Authorization header bearer token with API key</p>
                  <b>Header</b> <br />
                  <div className="input-group mb-3">
                    <input type="text" disabled value={`Authorization:Bearer ${user?.apiKey}`} className="form-control" />
                  </div>
                  <br />
                  <div>
                    <b>Get Balance</b>
                    <br />
                    /payment/balance <br />
                    Request Body <br />
                    <div className="input-group mb-3">
                      <input type="text" disabled value={``} className="form-control" />
                    </div>
                    Response Body <br />
                    <div className="input-group mb-3">
                      <input type="text" disabled value={`{"otpCredit": 70,"walletBalance": 9}`} className="form-control" />
                    </div>
                  </div>
                  <div>
                    <b>Get Number</b>
                    <b style={{ fontSize: "0.8rem" }}> (List of serviceID please refer to Home page)</b>
                    <br />
                    /number/new <br />
                    Request Body <br />
                    <div className="input-group mb-3">
                      <input type="text" disabled value={`{"serviceID":1}`} className="form-control" />
                    </div>
                    Response Body <br />
                    <div className="input-group mb-3">
                      <input type="text" disabled value={`{"number": "6283837986345","orderID": "7DUIvG0eGEmxEhEda4sb"}`} className="form-control" />
                    </div>
                  </div>
                  <br />
                  <div>
                    <b>Number Status</b>
                    <br />
                    /number/status <br />
                    Request Body <br />
                    <div className="input-group mb-3">
                      <input type="text" disabled value={`{"orderID":"7DUIvG0eGEmxEhEda4sb"}`} className="form-control" />
                    </div>
                    Response Body <br />
                    orderStatus - 0-pending 1-completed 2-cancelling 3-cancelled <br />
                    code - otp code
                    <div className="input-group mb-3">
                      <input type="text" disabled value={`{"orderStatus": 2,"code": "1234"}`} className="form-control" />
                    </div>
                  </div>
                  <br />
                  <div>
                    <b>Cancel Number</b>
                    <b style={{ fontSize: "0.8rem" }}> (You can only cancel order 3 minutes after buy the number)</b>
                    <br />
                    /number/cancel <br />
                    Request Body <br />
                    <div className="input-group mb-3">
                      <input type="text" disabled value={`{"orderID":"7DUIvG0eGEmxEhEda4sb"}`} className="form-control" />
                    </div>
                    Response Body <br />
                    <div className="input-group mb-3">
                      <input type="text" disabled value={`{"isSuccess": true,"message": "Success"}`} className="form-control" />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Documentation;
