import React from "react";
import "./App.css";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter as Router } from "react-router-dom";
import AuthWrapper from "./components/AuthWrapper";

const firebaseConfig = {
  apiKey: "AIzaSyAQo_i6I_hthHgo-L9-kPTnDAMoF-XHZdg",
  authDomain: "virtual-otp-3c3a1.firebaseapp.com",
  projectId: "virtual-otp-3c3a1",
  storageBucket: "virtual-otp-3c3a1.appspot.com",
  messagingSenderId: "835169558131",
  appId: "1:835169558131:web:d940e947dad6f8d2eb9406",
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  return (
    <Router>
      <AuthWrapper />
    </Router>
  );
}

export default App;
