import { where, query, orderBy, collection, onSnapshot, getFirestore } from "@firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../components/AuthWrapper";
import ITransaction from "../interfaces/transaction";
import { limit } from "firebase/firestore";

const useFetchTransactions = () => {
  const user = useContext(AuthUserContext);
  const firestore = getFirestore();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    setIsLoading(true);
    const transactionCollectionRef = collection(firestore, "transaction");
    return onSnapshot(
      query(transactionCollectionRef, where("userID", "==", user.uid), orderBy("transactionDate", "desc"), limit(10)),
      (snapshot) => {
        setIsLoading(false);
        setTransactions(
          snapshot.docs.map((doc) => {
            const transaction = doc.data();
            return {
              ...transaction,
              transactionDate: transaction.transactionDate && new Date(transaction.transactionDate.seconds * 1000),
            } as ITransaction;
          })
        );
      },
      (err) => {
        console.log(err);
        setIsLoading(false);
      }
    );
  }, [user, firestore]);

  return { transactions, isLoading };
};

export default useFetchTransactions;
