import React from "react";

function Footer() {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-inline"></div>
      <strong>
        Copyright &copy; 2019-2022 <a href="">Virtual OTP Solutions</a>.
      </strong>{" "}
      All rights reserved. Contact: admin.v-otp@protonmail.com
    </footer>
  );
}

export default Footer;
