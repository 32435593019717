import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import Documentation from "./Documentation";
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import Reload from "./Reload";
import Profile from "./Profile";

function MyRoute() {
  return (
    <Fragment>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/reload">
          <Reload />
        </Route>
        <Route path="/api">
          <Documentation />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="*">
          <Home />
        </Route>
      </Switch>
    </Fragment>
  );
}

export default MyRoute;
